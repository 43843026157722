export default () => (
  <div className="birthdayCard">
    <div className="cardFront">
      <h3 className="happy">Happy Birthday Georgia!</h3>
      <div className="balloons">
        <div className="balloonOne" />
        <div className="balloonTwo" />
        <div className="balloonThree" />
        <div className="balloonFour" />
      </div>
    </div>
    <div className="cardInside">
      <h3 className="back">Happy 31st Birthday!!</h3>
      <p>Dear Georgia,</p>
      <p>
        Here's to another wonderful year together! Today will be full of
        laughter, food, and presents, and I continue to be happy to be a part of
        your life. Love you! - Mark
      </p>
      <p className="name"></p>
    </div>
  </div>
);
